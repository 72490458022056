import Vue from 'vue'
import axios from 'axios';
import Router from 'vue-router'
Vue.use(Router)

import sideSite from "@/views/sideSite"
import main from "@/views/main"

import productDetail from "@/views/productDetail"

// import join from "@/views/member/join"
// import joinPage from "@/views/member/joinPage"
import login from "@/views/member/login"

import search from "@/views/search"
import cartPage from "@/views/cartPage"

import adminPage from "@/views/admin/adminPage"
import productJoin from "@/views/admin/sub/productJoin"
import imgUpload from "@/views/admin/sub/imgUpload"
import colorChange from "@/views/admin/sub/colorChange"


const router =  new Router({
  mode : 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'sideSite',
      component: sideSite
    },
    {
      path: '/home',
      name: 'main',
      component: main
    },
    {
      path: '/productDetail',
      name: 'productDetail',
      component: productDetail
    },
    // {
    //   path: '/join',
    //   name: 'join',
    //   component: join
    // },    
    // {
    //   path: '/joinPage',
    //   name: 'joinPage',
    //   component: joinPage
    // },    
    {
      path: '/login',
      name: 'login',
      component: login
    },    
    {
      path: '/search',
      name: 'search',
      component: search
    },    
    {
      path: '/cartPage',
      name: 'cartPage',
      component: cartPage
    },    
    {
      path: '/admin',
      name: 'adminPage',
      component: adminPage,
      beforeEnter: (to, from, next) => {
        console.log('to:: ',to);
        console.log('from:: ',from);


        axios.get('/sub/loginCheck').then((res) => {
          if(res.data.code < 0) {
            next('/login');
          } else {
            next();
          }
            
        }).catch(
          function (error) {
          console.log(error);
          }
        )

      },
      children:[
        {
          path: 'productJoin',
          name: 'productJoin',
          component: productJoin
        },
        {
          path: 'imgUpload',
          name: 'imgUpload',
          component: imgUpload
        },
        {
          path: 'colorChange',
          name: 'colorChange',
          component: colorChange
        },
      ]
    },    
    
  ]
  
})

router.afterEach((to, from) => {
  console.log(to, from)
  const title = '키드키득(KIDKIDK)'
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router;