<template>
  <v-app>
      
    <v-container fluid >
      <v-app-bar app color="#A9E2F3" height="40px" class="elevation-0">
        <!-- <v-list-item-title class="text-h5 font-weight-bold" style="height: 26px">관리자 페이지</v-list-item-title> -->
        <v-row>

          <v-col cols="2">
            <v-btn 
              fab x-small 
              class="elevation-0"
              @click="menuClicked"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn 
              fab x-small 
              class="elevation-0"
              @click="homeClicked"
            >
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="8">
            <v-btn 
              fab x-small 
              class="elevation-0 ma-0 pa-0 mr-2" 
              @click="logoutClicked"
              style="float: right;"
            >
              <v-icon>mdi-logout</v-icon>
            </v-btn>
            <!-- <v-btn 
              fab 
              x-small 
              class="elevation-0 ma-0 pa-0"
              style="float: right;"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn> -->
          </v-col>
            
        </v-row>
      </v-app-bar>
      

      <v-row class="hei-100vh-auto ma-0 pa-0" >
        <!-- <v-col cols="1" class="hei-100vh-auto pa-0" style="background-color: rgba(241,211,107);min-height: 100vh;">
          <v-list nav dense class="bg-lightblue" style="background-color: rgba(241,211,107);;">
            <v-list-group>
              <template v-slot:activator >
                <v-list-item-content>
                  <v-list-item-title >상품연결</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                class="pa-0"
                to="/admin/productJoin"
              >
                <v-list-item-content class="pl-5">
                  <v-list-item-title>링크연결</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="pa-0"
                to="/admin/imgUpload"
              >
                <v-list-item-content class="pl-5">
                  <v-list-item-title>이미지 업로드</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>

          <v-divider/>
        </v-col> -->
        <!-- <v-col cols="12">
          <v-main fluid style="padding-top:0px;" >
            <router-view />
          </v-main>
        </v-col> -->
        
      </v-row>
      
    </v-container>
    <router-view />
    <v-navigation-drawer
      v-model="drawer"
      top
      temporary
      absolute
      :style="`z-index: 9999;width: 50%;background-color: rgba(241,211,107);`"
      height="100vh"
    >
    <v-list nav dense class="bg-lightblue" style="background-color: rgba(241,211,107);">
      <v-list-group>
        <template v-slot:activator >
          <v-list-item-content>
            <v-list-item-title >상품연결</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          class="pa-0"
          to="/admin/productJoin"
        >
          <v-list-item-content class="pl-5">
            <v-list-item-title>링크연결</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="pa-0"
          to="/admin/imgUpload"
        >
          <v-list-item-content class="pl-5">
            <v-list-item-title>이미지 업로드</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="pa-0"
          to="/admin/colorChange"
        >
          <v-list-item-content class="pl-5">
            <v-list-item-title>색상 변경</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list-group>
    </v-list>
    </v-navigation-drawer>


  </v-app>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
    }
  },
  methods: {
    logoutClicked() {
      if(confirm('로그아웃 하시겠습니까?')) {
        this.$http.post("/sub/logout").then((res) => {
          console.log(res.data);
          if(res.data.code == 0) {
            alert('로그아웃 되었습니다.');
            this.$router.push('/')
          } else {
            alert('로그아웃 실패');
          }

        }).catch(
          function (error) {
            console.log(error);
          }
        );
      }
      
    },//logout end
    menuClicked() {
      this.drawer = true;
    },//menuClicked end
    homeClicked() {
      window.open('/')
    },
  }
}
</script>